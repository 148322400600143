<template>
  <div class="flex-box vertical">
    <div class="data-title">
      <div class="title-left flex-box">
        <span>共{{list.length}}条记录</span>
      </div>
      <a-form-model class="query-form" layout="inline">
        <a-form-model-item label="年份">
          <a-select
              style="width: 180px"
              placeholder="请选择年份"
              :options="yearOptions"
              @change="getList"
              v-model="form.year">
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="data-area">
      <a-table
          ref="dataTable"
          bordered
          :columns="columns"
          row-key="userid"
          :data-source="list"
          :loading="loading"
          :pagination="false">
      </a-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "book-learn",
  props: {
    info: Object
  },
  data() {
    return {
      list: [],
      loading: false,
      columns: [
        {title: '年份', dataIndex: 'year', customRender: text => text + "年"},
        {title: '月份', dataIndex: 'month', customRender: text => text + "月"},
        {title: '学习时长（h）', dataIndex: 'hours', customRender: text => text + 'h'},
      ],
      yearOptions: [],
      form: {}
    }
  },
  created() {
    const start = 2022;
    const today = new Date();
    this.yearOptions = new Array(today.getFullYear() - start + 1).fill(start).map((item, i) => {
      const year = item + i;
      return {
        value: year,
        label: `${year}年`
      }
    });
    this.form = { year: today.getFullYear() };
    this.getList();
  },
  methods: {
    getList() {
      const info = this.info;
      if (!info) return;
      this.loading = true;
      this.$axios("/admin/learn-time/record-list", {
        params: {
          ...this.form,
          user_id: info.wx_user_id
        }
      }).then(res => {
        if(res.error === 0) {
          this.list = res.data;
        } else {
          this.$message.warning(res.msg)
        }
      }).finally(() => this.loading = false);
    }
  }
}
</script>

<style scoped>

</style>

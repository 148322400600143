<template>
  <div class="content md flex-box vertical" v-if="info">
    <template v-if="info.base_info">
      <div class="staff-top">
        <staff-brief :info="info"></staff-brief>
      </div>
      <a-tabs class="book-detail-tabs" default-active-key="1" >
        <a-tab-pane key="1" tab="基础信息">
          <detail-list class="base-info" :list="baseList"></detail-list>
        </a-tab-pane>
        <a-tab-pane key="2" :tab="info.type === 1 ? '学修经历' : '成长记录'">
          <div class="grow-list" v-if="growList && growList.length > 0">
            <a-timeline>
              <a-timeline-item v-for="g in growList" :key="g.id">
                <div>{{ g.create_time }}</div>
                <div>
                  <open-data type="userName" :openid="g.operate_wx_user" v-if="g.operate_wx_user"/>
                  <span v-if="g.type == 5">从【<open-data type="departmentName" :openid="g.old_dept"/>】转至【<open-data type="departmentName" :openid="g.new_dept"/>】</span>
                  <span v-else>{{g.showText}}</span>
                </div>
              </a-timeline-item>
            </a-timeline>
          </div>
          <a-empty :image="emptyImage" v-else />
        </a-tab-pane>
        <a-tab-pane key="3" tab="评价记录">
          <a-table
            ref="dataTable"
            :columns="columns"
            row-key="id"
            :data-source="assList"
            :pagination="pagination"
            :scroll="{y: h}"
            @change="handleTableChange">
            <template v-slot:name="text">
              <open-data type="userName" :openid="text" />
            </template>
            <template v-slot:action="text, record">
              <div class="row-btn">
                <a class="txt-btn" @click.stop="showAssDetail(record)">查看详情</a>
              </div>
            </template>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="4" tab="考勤记录" v-if="info.type === 2">
          <book-attendance :info="info" />
        </a-tab-pane>
        <a-tab-pane key="5" tab="汇报记录">
          <book-report :info="info" />
        </a-tab-pane>
        <a-tab-pane key="6" tab="学习记录">
          <book-learn :info="info" />
        </a-tab-pane>
        <a-tab-pane key="7" tab="选拔记录">
          <book-prefer :info="info" />
        </a-tab-pane>
      </a-tabs>
    </template>
    <div class="hr-detail-tip" v-else>
      <open-data type="userName" :openid="info.wx_user_id"></open-data>
      <span>的资料尚未完善</span>
      <router-link replace :to="{name: 'HRBookForm', params: {type: 'staff'}, query: {b: info.wx_user_id}}" class="txt-btn">去完善</router-link>
    </div>
  </div>
</template>

<script>
import detailList from "@/layouts/DetailList.vue";
import staffBrief from "@/components/staff-brief.vue";
import {dealStaff} from "@/common/hr/book";
import {getStaffDetailList} from "@/common/hr/form";
import {getAssItemStatusText, getAssTypeName} from "@/common/hr/ass";
import { Empty } from 'ant-design-vue';
import bookAttendance from "./book-attendance.vue";
import bookReport from "./book-report.vue";
import bookLearn from "./book-learn.vue";
import bookPrefer from "./book-prefer.vue";
export default {
  name: "HRBookDetail",
  components: {
    detailList,
    staffBrief,
    bookReport,
    bookLearn,
    bookAttendance,
    bookPrefer,
  },
  data() {
    return {
      info: null,
      emptyImage: Empty.PRESENTED_IMAGE_SIMPLE,
      baseList: [],
      growList: [],
      assList: [],
      h: 500,
      columns: [
        {title: '开始时间', dataIndex: 'begin_time'},
        {title: '评价名称', dataIndex: 'programName'},
        {title: '评价类型', dataIndex: 'typeText'},
        {title: '评价状态', dataIndex: 'statusText'},
        {title: '截止时间', dataIndex: 'end_time'},
        {title: '操作', key: 'action', fixed: 'right', width: 120, scopedSlots: { customRender: 'action'}}
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showSizeChanger: true
      },
    }
  },
  computed: {
    id() {
      return this.$route.params.id;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      let id = this.id;
      let url = `/admin/evaluate-user?filter[wx_user_id]=${id}`;
      this.$axios(url).then(res => {
        if(res.data && res.data.length > 0) {
          let user = res.data[0];
          dealStaff(user);
          this.info = user;
          this.baseList = getStaffDetailList(user);
        } else {
          this.info = {wx_user_id: id};
        }
      });
      this.getAssList();
      this.getHrApplyList();
    },
    /**
     * 成长记录
     */
    getHrApplyList() {
      const id = this.id;
      const url = `/admin/evaluate-user-develop?sort=-id&pageSize=100&filter[wx_user_id]=${id}`;
      this.$axios({
        url,
      }).then(res => {
        const list = res.data;
        list.filter(item => {
          const detail = item.detail ? JSON.parse(item.detail) : {};
          let text = "";
          const type = item.type;
          const title = ["进单", "离单", "入职", "离职", "调岗"][item.type - 1];
          const status = ["在单", "离单", "在职", "离职", "调岗"][item.type - 1]
          if (item.operate_wx_user) {
            text = `修改状态为【${status}】`;
            if ((type === 1 || type === 3) && detail.entry_date) {
              text += `，${title}日期为${detail.entry_date}`
            } else if ((type === 2 || type === 3) && detail.leave_date) {
              text += `，${title}日期为${detail.leave_date}`
            }
          } else {
            switch (type) {
              case 1:
              case 3: {
                text = `${title}，状态为【${status}】`;
                if (detail.entry_date) {
                  text += `，${title}日期为${detail.entry_date}`;
                }
                break;
              }
              case 2:
              case 4: {
                text = `${title}，状态为【${title}】`;
                if(detail.leave_date) {
                  text += `，${title}日期为${detail.leave_date}`;
                }
                break;
              }
              case 5:
                item.old_dept = detail.old_dept;
                item.new_dept = detail.new_dept;
                break;
            }
          }
          item.showText = text;
        });
        this.growList = list;
      });
    },
    showAssDetail(record) {
      this.$store.commit("setDetail", {type: 'ass', obj: record})
      this.$router.push({name: "AssessmentDetail", params: {id: record.id}});
    },
    handleTableChange(pagination) {
      let pager = { ...this.pagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      this.pagination = pager;
      this.getAssList();
    },
    getAssList() {
      let id = this.id;
      let {current, pageSize} = this.pagination;
      let url = `/admin/evaluate-item?sort=-id&expand=list&page=${current}&pageSize=${pageSize}&filter[user_id]=${id}`;
      this.$axios(url).then(res => {
        let list = res.data;
        list.forEach(ass => {
          ass.programName = ass.list ? ass.list.title : '-';
          ass.typeText = ass.program ? getAssTypeName(ass.program.type) : '-';
          ass.statusText = getAssItemStatusText(ass.status);
        });
        this.assList = list;
        if(res.page) {
          this.$set(this.pagination, 'total', res.page.totalCount);
        }
      });
    }
  }
}
</script>

<style lang="less">
.book-detail-tabs {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  .ant-tabs-content {
    flex: 1;
    min-height: 0;
  }
  .ant-tabs-tabpane {
    height: 100%;
    overflow: auto;
  }
}
.hr-detail-tip {
  margin-top: 100px;
  text-align: center;
  font-size: 16px;
  .txt-btn {
    margin-left: 0.5em;
  }
}
.staff-top {
  padding: 16px;
  border: var(--border);
}
.base-info {
  padding: 16px;
}
.grow-list {
  padding: 16px 48px;
}
</style>

<template>
  <div class="content flex-box vertical">
    <div class="data-title">
      <div class="title-left flex-box">
        <span>共{{pagination.total}}条记录</span>
      </div>
      <a-form-model class="query-form" layout="inline">
        <template v-if="!book">
          <a-form-model-item label="">
            <a-select
                style="width: 180px"
                placeholder="选择身份筛选"
                :options="identityList"
                v-model="form.identity"
                @change="identityChange">
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="">
            <a-select
                style="width: 180px"
                placeholder="选择打卡规则筛选"
                :options="ruleList"
                v-model="form.rule"
                @change="ruleChange">
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="">
            <a-select
                style="width: 180px"
                placeholder="选择打卡地点筛选"
                :options="locationList"
                v-model="form.loc"
                @change="getList">
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="">
            <a-select
                style="width: 180px"
                placeholder="选择打卡状态筛选"
                :options="statusList"
                v-model="form.status"
                @change="getList">
            </a-select>
          </a-form-model-item>
        </template>
        <a-form-model-item label="">
          <a-range-picker
            v-model="form.time"
            :disabled-date="disabledDate"
            :allow-clear="false"
            :placeholder="['开始时间', '结束时间']"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            @change="getList"/>
        </a-form-model-item>
        <a-form-model-item label="" v-if="!book">
          <a-input v-model="form.keyword" placeholder="输入姓名/法名查询"></a-input>
        </a-form-model-item>
      </a-form-model>
      <a-space class="title-btn">
        <a-button @click="getList">查询</a-button>
        <a-button type="primary" @click="exportData" :loading="exporting">{{exporting ? '正在导出' : '数据导出'}}</a-button>
      </a-space>
    </div>
    <div class="data-area">
      <a-table
          ref="dataTable"
          :columns="columns"
          :data-source="list"
          :loading="loading"
          :pagination="pagination"
          :scroll="{y: h}"
          @change="handleTableChange">
        <template v-slot:name="text">
          <open-data type="userName" :openid="text" />
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import {downLoadBlobFile, getDate} from "../../common/js/tool";

export default {
  name: "Attendance",
  props: {
    book: String
  },
  data() {
    return {
      loading: false,
      list: [],
      form: {},
      h: 500,
      columns: [
        {title: '日期', dataIndex: 'dateStr', width: 80},
        {title: '星期', dataIndex: 'dayStr', width: 80},
        {title: '法名/姓名', dataIndex: 'name'},
        {title: '部门', dataIndex: 'dept'},
        {title: '身份', dataIndex: 'identity', width: 80},
        {title: '最早打卡时间', dataIndex: 'first', width: 120, customRender: text => <span>{text || '--'}</span>},
        {title: '最晚打卡时间', dataIndex: 'last', width: 120, customRender: text => <span>{text || '--'}</span>},
        {title: '所属规则', dataIndex: 'group_name', customRender: text => <span>{text || '--'}</span>},
        {title: '打卡地点', dataIndex: 'location_title', customRender: text => <span>{text || '--'}</span>},
        {title: '异常', dataIndex: 'exception', customRender: text => text ? <span class="check-error">{text}</span> : <span>--</span>},
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showSizeChanger: true
      },
      source: [],
      identityList: [
        {key: 0, title: "全部"},
        {key: 1, title: "僧众"},
        {key: 2, title: "职工"},
      ],
      ruleList: [
        {key: -1, title: "全部"},
      ],
      locationList: [
        {key: 0, title: "全部"},
      ],
      statusList: [
        {key: 0, title: "全部"},
        {key: 2, title: "异常"},
        {key: 1, title: "正常"},
      ],
      exporting: false
    }
  },
  created() {
    this.init();
  },
  mounted() {
    this.setHeight();
    window.addEventListener("resize", this.setHeight, false);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setHeight, false);
  },
  methods: {
    init() {
      const book = this.book;
      // 个人考勤记录默认查当月的
      if (book) {
        const today = new Date();
        today.setDate(1);
        const start = today.pattern("yyyy-MM-dd");
        today.setMonth(today.getMonth() + 1);
        today.setDate(today.getDate() - 1);
        const end = today.pattern("yyyy-MM-dd");
        this.form = {time: [start, end], keyword: book};
      }
      // 考勤统计默认查当天的
      else {
        const today = new Date().pattern("yyyy-MM-dd");
        this.form = {time: [today, today]};
        this.getRuleList();
      }
      this.getList();
    },
    getRuleList() {
      this.$axios("/admin/common/get-kq-rule", { noTempleFilter: true}).then(res => {
        this.ruleList.push(...res.map((item, i) => Object({key: i, title: item})));
      });
    },
    exportData() {
      if(this.exporting) return;
      this.exporting = true;
      let fileName = "考勤数据";
      let url = '/admin/common/kq-export' + this.getQuery(true);
      this.$axios(url, {noTempleFilter: true, responseType: 'blob'}).then(res => {
        this.exporting = false;
        downLoadBlobFile(res, fileName);
      }).catch(() => {
        this.exporting = false;
      });
    },
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    identityChange() {
      const identity = this.form.identity;
      if(identity > 0) {
        this.$set(this.form, 'rule', -1);
      }
      this.getList();
    },
    ruleChange() {
      const rule = this.form.rule;
      if(rule > 0) {
        if(rule == 3) {
          this.$set(this.form, 'identity', 1);
        } else {
          this.$set(this.form, 'identity', 2);
        }
      }
      this.getList();
    },
    handleTableChange(pagination) {
      let pager = { ...this.pagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      this.pagination = pager;
      // this.getList();
    },
    getQuery(ex) {
      let form = this.form;
      let query = "";
      let {identity, time, loc, status, keyword, rule} = this.form;
      if(time && time.length == 2) {
          let start = getDate(`${form.time[0]} 00:00:00`);
          let end = getDate(`${form.time[1]} 23:59:59`);
          start = Math.round(start.getTime() / 1000);
          end = Math.round(end.getTime() / 1000);
          query += `?begin_time=${start}&end_time=${end}`;
      }
      if(rule >= 0) {
          query += `&rule=${rule}`;
      } else if(identity > 0) {
          query += `&role=${identity}`
      }
      if(loc > 0) {
          query += `&location=${loc}`
      }
      if(status > 0) {
          query += `&absent=${status}`
      }
      if(keyword) {
          query += `&name=${keyword}`
      }
      if(ex) {
          query += "&export=1"
      }
      return query;
    },
    getList() {
      if(this.loading) return;
      this.loading = true;
      let url = '/admin/common/kq-export' + this.getQuery();
      this.$axios({
        url,
        noTempleFilter: true
      }).then(res => {
        this.dealList(res);
      }).finally(() => {
        this.loading = false;
      });
    },
    setList() {
      let {identity, loc, status} = this.form;
      let list = this.source;
      if(identity > 0) {
        list = list.filter(item => item.group_id == identity);
      }
      if(loc) {
        list = list.filter(item => item.location_title == loc);
      }
      if(status) {
        list = list.filter(item => status == 1 ? !item.exception : !!item.exception);
      }
      this.list = list;
      this.$set(this.pagination, 'total', list.length);
    },
    dealList(res) {
      const keys = Object.keys(res);
      keys.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
      let locationList = this.locationList;
      let list = [];
      keys.forEach(key => {
        const date = new Date(key);
        const dayData = res[key];
        dayData.forEach(day => {
          const identity = this.identityList.find(item => item.key == day.group_id);
          const item = {
            ...day.info,
            identity: identity ? identity.title : "--",
            group_id: day.group_id,
            group_name: day.group_name,
            dateStr: key,
            dayStr: date.pattern("EEE"),
            exception: day.checkin_exception
          };
          const checkin_data = day.checkin_data;
          if(checkin_data && checkin_data.length > 0) {
            checkin_data.forEach(c => {
              if(locationList.findIndex(l => l.key === c.location_title) === -1) {
                locationList.push({key: c.location_title, title: c.location_title});
              }
            })
            item.location_title = checkin_data[0].location_title;
          }
          item.first = day.real_time[0];
          item.last = day.real_time[1];
          list.push(item);
        })
      });
      this.locationList = locationList;
      this.list = list;
      this.source = list;
      this.$set(this.pagination, 'total', list.length);
    },
    setDayList(source) {
      let time = this.form.time;
      let begin = getDate(`${time[0]} 00:00:00`), end = getDate(`${time[1]} 00:00:00`);
      let oneDay = 24 * 60 * 60 * 1000;
      let num = Math.round((end.getTime() - begin.getTime()) / oneDay) + 1;
      let list = [];
      let i = 0;
      while (i < num) {
        let day = begin.pattern("yyyy/MM/dd");
        source.forEach(item => {
          let ids = item.user_ids;
          ids.forEach(user => {
            let temp;
            let userData = item.checkin_data.filter(c => c.dateStr == day && c.userid == user);
            if(userData.length > 0) {
              userData.sort((a, b) => a.checkin_time - b.checkin_time);
              temp = {...userData[0], rule_id: item.id}
            } else {
              temp = {
                rule_id: item.id,
                userid: user,
                dateStr: day,
                dayStr: begin.pattern("EEE"),
                exception: '未打卡',
                identity: item.id == 2 ? '僧众' : '职工'
              }
            }
            list.push(temp);
          })
        });
        i++;
        begin.setDate(begin.getDate() + 1);
      }
      this.list = list;
      this.source = list;
      this.$set(this.pagination, 'total', list.length);
    },
    setHeight() {
      let dataTale = this.$refs.dataTable;
      if (dataTale) {
        const area = this.$el.querySelector(".data-area");
        const head = this.$el.querySelector(".ant-table-header");
        this.h = area.clientHeight - (head?.clientHeight ?? 54) - 64;
      }
    },
  }
}
</script>

<style scoped lang="less">
  .check-error {
    color: @error-color;
  }
</style>

<template>
  <Attendance style="padding:0;height: 100%;" :book="name" v-if="name"/>
  <a-result title="信息未完善，暂时无法查询考勤记录" v-else />
</template>

<script>
import Attendance from "@/views/hr/Attendance.vue";
export default {
  name: "book-attendance",
  props: {
    info: Object,
  },
  components: {
    Attendance
  },
  computed: {
    name() {
      let res = "";
      const info = this.info;
      if (info) {
        if(info.type === 1) {
          res = info.fm;
        } else {
          res = info.name;
        }
      }
      return res;
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <form-area
      ref="bookForm"
      class="content md book-form"
      layout="horizontal"
      form-item-row-type="flex"
      :confirm-text="entity ? '保存' : '提交'"
      :label-col="{flex: '120px'}"
      :wrapper-col="{flex: 'auto'}"
      :items="items"
      :entity="form"
      @cancel="cancel"
      @confirm="confirm" v-if="items"></form-area>
  <div class="content md" v-else-if="items === false">
    <div class="form-empty-tip">
      <span>流程尚未配置，请联系管理员配置</span>
    </div>
  </div>
</template>

<script>
import {getTypeForm, typeForm} from "../../../common/hr/form";
import {getUser, getUserWxId, setUser} from "../../../common/js/storage";

export default {
  name: "HRBookForm",
  data() {
    return {
      form: {},
      items: null,
      entity: null,
      temp: null,
      loading: false
    }
  },
  computed: {
    type() {
      return this.$route.params.type;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      let {params, query} = this.$route;
      let type = params.type, id = query.b;
      // 编辑员工
      if(type == 'staff' && id) {
        this.getEntity(id);
      } else {
        // 申请
        this.getHRApplyTemp(type);
      }
    },
    getHRApplyTemp(type) {
      let items = getTypeForm(type);
      if(items) {
        let tempType = {monk: 1, um: 2, board: 3, ub: 4, trans: 5}[type];
        let url = `/admin/apply-temp?filter[type]=${tempType}&filter[is_oa]=2`;
        this.$axios(url).then(res => {
          let list = res.data;
          if(list && list.length > 0) {
            this.temp = list[0];
            this.items = items
          } else {
            this.items = false;
          }
        });
        if(tempType == 2) {
          this.form = {fm: [getUserWxId()]};
        }
      } else {
        this.$router.replace({name: 'NotFound'});
      }
    },
    setFormItems() {
      let entity = this.entity;
      let base = getTypeForm('base');
      base[0].listeners = {
        change: function (val) {
          let list = []
          if(val == 1) {
            list = getTypeForm("monk");
          } else if(val == 2) {
            list = getTypeForm("board");
          }
          this.controls.splice(1, this.controls.length - 1, ...list);
        }
      }
      if(entity) {
        let type = entity.type;
        if(type == 1) {
          base = base.concat(getTypeForm('monk'))
        } else if(type == 2) {
          base = base.concat(getTypeForm("board"))
        }
        if(entity.base_info) {
          try {
            let detail = JSON.parse(entity.base_info);
            let form = {
              name: entity.name,
              age: entity.age,
              type: entity.type,
              ...detail
            }
            if(type == 1) {
              form.fm = entity.fm;
              form.fh = entity.fh
            }
            this.form = form;
          } catch (e) {
            console.error(e)
          }
        }
      }
      this.items = base;
    },
    getEntity(id) {
      let url = `/admin/evaluate-user?filter[wx_user_id]=${id}`;
      this.$axios(url).then(res => {
        if(res.data && res.data.length > 0) {
          const entity = res.data[0];
          if (entity.type === 0) {
            entity.type = undefined;
          }
          this.entity = entity;
          this.setFormItems();
        } else {
          // 没有先插入一条evaluate-user
          this.$axios(`/admin/common/get-user?id=${id}`).then(res => {
            if(res.error == 0) {
              let user = res.data;
              this.$axios({
                url: "/admin/evaluate-user",
                method: 'POST',
                data: {
                  dept_id: user.main_department,
                  status: 1,
                  wx_user_id: user.userid
                }
              }).then(entity => {
                this.entity = entity;
                this.setFormItems();
              })
            }
          });
        }
      });
    },
    cancel() {
      this.$router.back();
    },
    confirm(data) {
      if(this.loading) return;
      this.loading = true;
      let type = this.$route.params.type;
      if(type == 'staff' && this.entity) {
        this.updateStaff(data);
      } else {
        this.addHRApply(data);
      }
    },
    addHRApply(data) {
      let temp = this.temp;
      let body = {
        user_id: getUserWxId(),
        temp_id: temp.id,
        detail: JSON.stringify(data),
        temp_snap: JSON.stringify(typeForm[this.type].items),
        temp_flow_snap: temp.flow,
        real_flow: temp.flow
      }
      this.$axios({
        url: '/admin/apply',
        method: 'POST',
        data: body
      }).then(() => {
        this.loading = false;
        this.$router.back();
      }).catch(() => {
        this.loading = false;
      });
    },
    updateStaff(data) {
      let entity = this.entity;
      let body = {
        name: data.name,
        type: data.type
      }
      let age = this.getAge(data.birthday);
      if(age !== null) {
        body.age = age;
      }
      delete data.name;
      delete data.age;
      if(data.type == 1) {
        body.fm = data.fm;
        body.fh = data.fh;
        delete data.fm;
        delete data.fh;
      }
      delete data.type;
      body.base_info = JSON.stringify(data);
      let url = `/admin/evaluate-user/${entity.id}`;
      this.$axios({
        url,
        method: 'PATCH',
        data: body
      }).then(res => {
        const user = getUser();
        user.evaluate_user = user.evaluate_user ? {...user.evaluate_user, ...res} : res;
        user.mobile = data.tel;
        setUser(user);
        this.loading = false;
        this.$router.replace({name: 'HRBookDetail', params: {id: entity.wx_user_id}})
      }).catch(() => {
        this.loading = false;
      })
    },
    getAge(birth) {
      birth = new Date(birth);
      if(birth) {
        let now = new Date();
        let age = now.getFullYear() - birth.getFullYear();
        if(now.getTime() < new Date(now.getFullYear(), birth.getMonth(), birth.getDate())) {
          age -= 1;
        }
        return age < 0 ? 0 : age;
      } else {
        return null;
      }
    }
  }
}
</script>

<style lang="less">
  .book-form {
    padding: 0;
  }
  .form-empty-tip {
    margin-top: 100px;
    text-align: center;
    font-size: 16px;
    .txt-btn {
      margin-left: 0.5em;
    }
  }
</style>

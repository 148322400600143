<template>
  <div class="flex-box vertical">
    <div class="data-title">
      <div class="title-left flex-box">
        <span>共{{list.length}}条记录</span>
      </div>
      <a-form-model class="query-form" layout="inline">
        <a-form-model-item label="年份">
          <a-select
              style="width: 180px"
              placeholder="请选择年份"
              :options="yearOptions"
              @change="getList"
              v-model="form.year">
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="data-area">
      <a-table
          ref="dataTable"
          bordered
          :columns="columns"
          row-key="quarter"
          :data-source="list"
          :loading="loading"
          :pagination="false">
      </a-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "book-prefer",
  props: {
    info: Object
  },
  data() {
    return {
      list: [],
      loading: false,
      yearOptions: [],
      form: {}
    }
  },
  computed: {
    columns() {
      const res = [
        {
          title: '年份',
          dataIndex: 'year',
          customRender: (text, row) => {
            if (row.total) {
              return {
                children: `${text}年度得分`,
                attrs: {
                  colSpan: 2,
                  align: 'center'
                },
              };
            } else {
              return text + '年';
            }
          },
        },
        {
          title: '季度',
          dataIndex: 'quarter',
          customRender: (text, row) => {
            if (row.total) {
              return {
                children: text,
                attrs: {
                  colSpan: 0
                },
              };
            } else {
              return text + "季度"
            }
          },
        },
        {title: '平均汇报次数得分', dataIndex: 'report_count_score'},
        {title: '平均工作响应时间得分', dataIndex: 'response_time_score'},
        {title: '评价得分', dataIndex: 'total_scores'},
        {title: '人才选拔综合指数', dataIndex: 'composite_score'},
      ];
      if (this.info && this.info.type === 2) {
        res.splice(3,0,  {title: '平均考勤正常率得分', dataIndex: 'normal_rate_score'})
      }
      return res;
    }
  },
  created() {
    const start = 2024;
    const today = new Date();
    this.yearOptions = new Array(today.getFullYear() - start + 1).fill(start).map((item, i) => {
      const year = item + i;
      return {
        value: year,
        label: `${year}年`
      }
    });
    this.form = { year: today.getFullYear() };
    this.getList();
  },
  methods: {
    getList() {
      const info = this.info;
      if (!info) return;
      this.loading = true;
      const year = this.form.year;
      const url = `/admin/evaluate-user/select-record`
      this.$axios(url, {
        noTempleFilter: true,
        params: { wx_user_id: info.wx_user_id, year, type: info.type }
      }).then(res => {
        if (res.error === 0) {
          const list = res.data.list;
          const keys = ["report_count_score", "response_time_score", "normal_rate_score", "total_score", "composite_score"];
          if(list.length === 4) {
            const total = res.data;
            const yearData = {
              total: true,
              year,
              quarter: 0
            };
            keys.forEach(k => yearData[k] = total[`${k}_sum`] || 0);
            list.push(yearData);
          }
          this.list = list;
        } else {
          this.$message.error(res.msg)
        }
      }).finally(() => this.loading = false);
    }
  }
}
</script>

<style scoped>

</style>
